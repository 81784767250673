import React, { useEffect } from 'react';
import clsx from 'clsx';

import { colorsByTheme } from '@utils/constants/colors';
import { Contacts } from '@utils/constants/global';
import { Cross, Cellphone, Envelope } from '@assets/icons';
import { useToggleBodyScroll } from '@utils/hooks/toggleBodyScroll';

import styles from './styles.module.scss';

type Props = {
  profileDropdown: JSX.Element;
  onClose: () => void;
};

const AdminBurgerMenu = (props: Props) => {
  const { profileDropdown, onClose } = props;
  const { disableScroll, enableScroll } = useToggleBodyScroll();

  useEffect(() => {
    disableScroll();
    return () => {
      enableScroll();
    };
  }, []);

  return (
    <section className={styles.adminBurgerMenu}>
      <div className={styles.adminBurgerMenu__header}>
        <Cross
          onClick={onClose}
          stroke={colorsByTheme['dark'].textPrimaryColor}
        />
      </div>

      <div className={styles.adminBurgerMenu__body}>
        <section className={styles.adminBurgerMenu__links} onClick={onClose}>
          {profileDropdown}
        </section>
      </div>

      <div className={styles.adminBurgerMenu__footer}>
        <a
          href={`tel:${Contacts.CALL_US}`}
          className={clsx([styles.header__phoneNumber, 'fade-animation'])}
        >
          <Cellphone fill={colorsByTheme['dark'].textPrimaryColor} />
        </a>
        <a
          className={styles.header__contactIcon}
          href={`mailto:${Contacts.CONTACT_EMAIL}`}
        >
          <Envelope fill={colorsByTheme['dark'].textPrimaryColor} />
        </a>
      </div>
    </section>
  );
};

export default AdminBurgerMenu;
